$isProduction: true;
$fa-font-path: null;

// Glyphicons font path
// $icon-font-path: "../fonts/";
// Theme
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1080px,
);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px,
);
$theme-colors: (
  primary: #e42e68,
  secondary: #4c5151,
);
$primary: map-get(
  $map: $theme-colors,
  $key: "primary",
);
$secondary: map-get(
  $map: $theme-colors,
  $key: "secondary",
);
// Colors
$text-color: #4c5151;
$black: #000;
$dark-brown: #6d3c17;
$alert-danger-text: #721c24;
$dark-green: #082401;
$yellow: #e6cc71;
$yellow-menu-item: #f7ce03;
$success-translucent: #26a699bb;
$success: #26a699;
$white-translucent: rgba(255, 255, 255, 0.26);
$table-body: #cccc98;
$table-header: #b0b06f;
$sea-green: #0c545f;
$hadith-border: #f8f1d7;
// Variables
$glow-time: 2s;
$line-height-h5: 2 !important;
$line-height-h4: 2.5;
