@import "common/variables";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */

@import url(https://fonts.tazkia.org/all.css);

@import "~bootstrap/scss/bootstrap";
// Common
@import "common/mixins";
// Layouts
@import "layouts/header";
@import "layouts/footer";
// Pages
@import "pages/home";

nav {
  @include dark-green-gradient-background();
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
