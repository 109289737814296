@font-face {
  font-family: Mehr Nastaliq;
  src: url("https://5.cdn.tazkia.org/assets/MehrNastaliq1beta.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  background-color: #cccc99;
  background-repeat: repeat;
  font-family: NassimArabic-Regular, sans-serif;
  direction: rtl;
  line-height: normal;
  background-image: url("https://7.cdn.tazkia.org/ur/assets/images/bg-2.jpg");
}

.tazkia_botton {
  width: 131px;
  height: 61px;
  margin: 20px 0 0 30px;
}

// Live bayan animation and stylization
.live-on-air {
  //display: none;
  direction: ltr;

  .live-icon {
    display: inline-block;
    margin: 0 auto;
    border-radius: 50%;
    background: red;
    width: 20px;
    height: 20px;
    animation: live 2s infinite;
  }

  .live-text {
    display: inline-block;
    text-align: center;
    color: red;
    font-family: arial;
    font-weight: bold;
    position: relative;
    left: 5px;
    top: -4px;
  }
}

@keyframes live {
  0%,
  100% {
    background: none;
  }

  50% {
    background: red;
  }
}
.play-inactive,
.play-active {
  width: 100%;
  height: 100%;
  //height: 120px;
  object-fit: cover;
}

.live-off-air {
  //display: none;
  direction: ltr;

  .off-air-icon {
    display: inline-block;
    margin: 0 auto;
    border-radius: 50%;
    background: rgb(134, 134, 134);
    width: 17px;
    height: 17px;
  }

  .off-air-text {
    display: inline-block;
    text-align: center;
    font-family: arial;
    font-weight: bold;
    position: relative;
    left: 5px;
    top: -4px;
  }
}

.live-bayan {
  background-color: $white-translucent;
  border: 1px solid $hadith-border;
}

.audio-player-container {
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.live-player-obj {
}

.footer {
  hr {
    border: 0.7px solid;
    border-color: $sea-green;
  }

  .footer-text {
    @include simple-text-shadow();
    color: $sea-green;
  }
}

// Css Spinner
.cssload-container {
  position: relative;
  width: 195px;
  height: 224px;
  overflow: hidden;
  margin: 0px auto;
}

.cssload-container .cssload-item {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 49px;
  height: 49px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.25);
}

.cssload-container .cssload-ventilator {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  animation: cssload-spin 1.15s ease-in-out infinite reverse;
  -o-animation: cssload-spin 1.15s ease-in-out infinite reverse;
  -ms-animation: cssload-spin 1.15s ease-in-out infinite reverse;
  -webkit-animation: cssload-spin 1.15s ease-in-out infinite reverse;
  -moz-animation: cssload-spin 1.15s ease-in-out infinite reverse;
  transition: all 0.23s ease;
  -o-transition: all 0.23s ease;
  -ms-transition: all 0.23s ease;
  -webkit-transition: all 0.23s ease;
  -moz-transition: all 0.23s ease;
}

.cssload-container .cssload-ventilator:before,
.cssload-container .cssload-ventilator:after {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.25);
  -ms-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.25);
  animation: cssload-shapeit 0.58s ease infinite alternate;
  -o-animation: cssload-shapeit 0.58s ease infinite alternate;
  -ms-animation: cssload-shapeit 0.58s ease infinite alternate;
  -webkit-animation: cssload-shapeit 0.58s ease infinite alternate;
  -moz-animation: cssload-shapeit 0.58s ease infinite alternate;
  transition: all 0.23s ease;
  -o-transition: all 0.23s ease;
  -ms-transition: all 0.23s ease;
  -webkit-transition: all 0.23s ease;
  -moz-transition: all 0.23s ease;
  content: "";
}
.cssload-container .cssload-ventilator:before {
  left: -125%;
  border-left: 2px solid rgb(52, 179, 168);
}
.cssload-container .cssload-ventilator:after {
  right: -125%;
  border-right: 2px solid rgb(52, 179, 168);
}
