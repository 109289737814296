@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;
  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_"),
  );
  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + "." + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin center {
  float: none;
  margin: 0 auto;
}

@mixin dark-green-gradient-background {
  background: -webkit-linear-gradient(
    top,
    rgb(83, 124, 70) 0%,
    rgb(51, 79, 44) 89%,
    rgb(55, 82, 46) 100%
  );
  background: -o-linear-gradient(
    top,
    rgb(83, 124, 70) 0%,
    rgb(51, 79, 44) 89%,
    rgb(55, 82, 46) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgb(83, 124, 70) 0%,
    rgb(51, 79, 44) 89%,
    rgb(55, 82, 46) 100%
  );
  background: -moz-linear-gradient(
    top,
    rgb(83, 124, 70) 0%,
    rgb(51, 79, 44) 89%,
    rgb(55, 82, 46) 100%
  );
  background: linear-gradient(
    to bottom,
    rgb(83, 124, 70) 0%,
    rgb(51, 79, 44) 89%,
    rgb(55, 82, 46) 100%
  );
}

@mixin yellow-gradient-background {
  background: -webkit-linear-gradient(
    top,
    rgb(251, 213, 100) 0%,
    rgb(197, 157, 43) 89%,
    rgb(187, 150, 38) 100%
  );
  background: -o-linear-gradient(
    top,
    rgb(251, 213, 100) 0%,
    rgb(197, 157, 43) 89%,
    rgb(187, 150, 38) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgb(251, 213, 100) 0%,
    rgb(197, 157, 43) 89%,
    rgb(187, 150, 38) 100%
  );
  background: -moz-linear-gradient(
    top,
    rgb(251, 213, 100) 0%,
    rgb(197, 157, 43) 89%,
    rgb(187, 150, 38) 100%
  );
  background: linear-gradient(
    to bottom,
    rgb(251, 213, 100) 0%,
    rgb(197, 157, 43) 89%,
    rgb(187, 150, 38) 100%
  );
}

@mixin light-brown-gradient-background($opacity: 1) {
  background: -webkit-linear-gradient(
    top,
    rgb(167, 167, 95) 33%,
    rgb(118, 118, 55) 135%,
    rgb(128, 128, 65) $opacity
  );
  background: -o-linear-gradient(
    top,
    rgba(167, 167, 95, $opacity) 33%,
    rgba(118, 118, 55, $opacity) 135%,
    rgba(128, 128, 65, $opacity) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(167, 167, 95, $opacity) 33%,
    rgba(118, 118, 55, $opacity) 135%,
    rgba(128, 128, 65, $opacity) 100%
  );
  background: -moz-linear-gradient(
    top,
    rgba(167, 167, 95, $opacity) 33%,
    rgba(118, 118, 55, $opacity) 135%,
    rgba(128, 128, 65, $opacity) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(167, 167, 95, $opacity) 33%,
    rgba(118, 118, 55, $opacity) 135%,
    rgba(128, 128, 65, $opacity) 100%
  );
}

@mixin dark-brown-gradient-background {
  background: -webkit-linear-gradient(
    top,
    rgb(114, 70, 26) 0%,
    rgb(78, 49, 22) 100%
  );
  background: -o-linear-gradient(
    top,
    rgb(114, 70, 26) 0%,
    rgb(78, 49, 22) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgb(114, 70, 26) 0%,
    rgb(78, 49, 22) 100%
  );
  background: -moz-linear-gradient(
    top,
    rgb(114, 70, 26) 0%,
    rgb(78, 49, 22) 100%
  );
  background: linear-gradient(
    to bottom,
    rgb(114, 70, 26) 0%,
    rgb(78, 49, 22) 100%
  );
}

@mixin font-medium {
  font-size: large;
}

@mixin background-image($imageName) {
  @if $isProduction==true {
    background-image: url("assets/images/" + $imageName);
  } @else {
    background: url("~/assets/images/" + $imageName);
  }
}

@mixin simple-text-shadow {
  text-shadow: 0px 1px 0px;
}

@mixin backwards-bending-shadow {
  -moz-border-radius: 0% 0% 100% 100% / 0% 0% 8px 8px;
  -webkit-border-radius: 0% 0% 100% 100% / 0% 0% 8px 8px;
  border-radius: 0% 0% 100% 100% / 0% 0% 8px 8px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 2px 3px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 2px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 3px;
}
