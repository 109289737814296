// Header styles
.top-brown-bar {
  // background-color: $dark-brown;
  @include dark-brown-gradient-background();
  height: 10px;
}

.top-brown-bar-2 {
  @include dark-brown-gradient-background();
  height: 7px;
}

.dark-green-bar {
  @include dark-green-gradient-background();
  height: 7px;
}

.kalima-bar {
  @include dark-green-gradient-background();

  .top-left {
    /* Durood Sharif */
    // @include background-image ("main_items.jpg");
    background-image: url("https://7.cdn.tazkia.org/ur/assets/images/main_items.jpg");
    background-repeat: no-repeat;
    background-position: calc(50% + 50px) -250px;
    width: 160px;
    height: 45px;
    margin-top: 20px;
  }

  .top-middle {
    /* Kalma */
    // @include background-image ("main_items.jpg");
    background-image: url("https://7.cdn.tazkia.org/ur/assets/images/main_items.jpg");
    background-repeat: no-repeat;
    background-position: calc(50%) -50px;
    margin-top: 9px;
    width: 190px;
    height: 70px;
  }

  .top-right {
    /* Allah-o-Akber */
    // @include background-image ("main_items.jpg");
    background-image: url("https://7.cdn.tazkia.org/ur/assets/images/main_items.jpg");
    background-repeat: no-repeat;
    background-position: calc(50% + 32.5px) 0px;
    width: 65px;
    height: 40px;
    margin-top: 22px;
  }
}

.light-brown-bar {
  @include light-brown-gradient-background();
  height: 7px;
}

.logo-bar {
  // @include background-image ("green-bg-pattern.jpg");
  background-image: url("https://7.cdn.tazkia.org/ur/assets/images/green-bg-pattern.jpg");
  background-repeat: repeat;

  .top-header-left {
    // @include background-image ("main_items.jpg");
    background-image: url("https://7.cdn.tazkia.org/ur/assets/images/main_items.jpg");
    background-repeat: no-repeat;
    background-position: calc(50% + 46px) -190px;
    width: 165px;
    height: 50px;
    margin-top: 29px;
  }

  .top-header-middle {
    /* LOGO */
    // @include background-image ("logo-urdu.gif");
    background-image: url("https://7.cdn.tazkia.org/ur/assets/images/logo-urdu.png");

    background-repeat: no-repeat;
    background-position: center;
    background-size: 96px 101px;
    width: 132px;
    height: 90px;
  }

  .top-header-right {
    // @include background-image ("main_items.jpg");
    background-image: url("https://7.cdn.tazkia.org/ur/assets/images/main_items.jpg");
    background-repeat: no-repeat;
    background-position: calc(50% - 26px) -120px;
    width: 165px;
    height: 70px;
    margin-top: 20px;
  }
}
